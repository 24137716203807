import { call, put, takeEvery, debounce } from 'redux-saga/effects';
import { Posts } from "../constants";
import { posts_array, post_data, posts_hash_tag, new_posts_array, popular_master, set_posts_slider, set_help } from "../actions/posts";
import authApi, { setApiAuthorizationHeader } from "../../services/authinstance";
import keys from "../../services/keys";
import Category from '../../services/api/posts';
function* fetchPost(data) {
  const { only_post, ...rest } = data.payload.data;
  try {
    const radius = yield Category.radius(data.payload);
    const posts = yield Category.posts({ data:rest, radius: radius.radius });
    if (!only_post) {
      const popularMaster = yield Category.popular_master({ ...data.payload, radius: radius.radius });
      yield put(popular_master(popularMaster));
    }

    // if (

    yield put(new_posts_array({ posts, query: rest }));
    // }
    // const masters = yield Category.popular_master(data.payload);
    // if (masters.status === 200 || masters.status === 201) {
    // yield put(popular_master(masters));
    // }
  } catch (e) {
    console.log(e);
  }
}

function* articles(data) {
  try {
    const articlesData = yield Category.article(data.payload);
    yield put(set_posts_slider(articlesData));
  } catch (e) {
    console.log(e);
  }
}
function* help(data) {
  
  try {
    const helpData = yield Category.help();
    yield put(set_help(helpData));
  } catch (e) {
    console.log(e);
  }
}


function* postsComments(data) {
  try {
    const { act_data, loading, cb, ...rest } = data.payload;
    loading ? yield put(loading(act_data)) : null;
    const comments = yield Category.comment_by_post_id(rest);
    cb ? yield put(cb(comments)) : null;
    // yield put(posts_comment_array(comments));
  } catch (e) {
    console.log(e);
  }
}
function* likeOrDislike(data) {
  try {
    const { cb, ...rest } = data.payload;
    const comments = yield Category.like_or_dislike(rest);
    cb ? cb() : null;
    // yield put(like_dislike(comments));
  } catch (e) {
    console.log(e);
  }
}
function* addComment(data) {
  try {
    const { loading, cb, lat, lng, query, ...rest } = data.payload;
    loading ? yield put(loading(rest)) : null;
    const comments = yield Category.add_new_comment(rest);
    cb ? yield put(cb(comments)) : null;
    setApiAuthorizationHeader(rest.token);
    const radius = yield authApi.get(`${keys.SERVER_URL}/utils/general/`);
    const response = lat && lng
      ? yield authApi.get(`${keys.SERVER_URL}timeline/post/post-by-radius/?radius=${radius.data.radius}&lat=${lat}&lang=${lng}&hide_hiddens=True&${query}&offset=0&limit=10`)
      : yield authApi.get(`${keys.SERVER_URL}timeline/post/post-by-radius/?radius=${radius.data.radius}&hide_hiddens=True&${query}&offset=0&limit=10`);
    yield put(new_posts_array({ posts: response.data, query }));
  } catch (e) {
    console.log(e);
  }
}
function* addView(data) {
  try {
    const comments = yield Category.add_view_post(data.payload);
    // yield put(add_comment(comments));
  } catch (e) {
    console.log(e);
  }
}
function* postPage(data) {
  try {
    const posts = yield Category.post_data(data.payload);
    yield put(post_data(posts));
  } catch (e) {
    console.log(e);
  }
}
function* hidePost(data) {
  try {
    const posts = yield Category.hide_post(data.payload);
    yield put(post_data(posts));
    const posts_list = yield Category.posts(data.payload);
    yield put(new_posts_array({ posts: posts_list }));
  } catch (e) {
    console.log(e);
  }
}
function* postReport(data) {
  try {
    const posts = yield Category.report_post(data.payload);
    const posts_list = yield Category.posts(data.payload);
    yield put(new_posts_array({ posts: posts_list }));
    yield put(post_data(posts));
  } catch (e) {
    console.log(e);
  }
}
// function* getPopularMasters(data) {
//   try {
//     const masters = yield Category.popular_master(data.payload);
//     yield put(popular_master(masters));
//   } catch (e) {
//     console.log(e);
//   }
// }
// GET_POSTS_SLIDER
export function* articlesSaga() {
  yield takeEvery(Posts.GET_POSTS_SLIDER, articles);
}
export function* helpSaga() {
  yield takeEvery(Posts.GET_HELP, help);
}
export function* postSaga() {
  yield takeEvery(Posts.POSTS_ARRAY_SAGA, fetchPost);
}
export function* CommentsSaga() {
  yield takeEvery(Posts.POSTS_COMMENT_SAGA, postsComments);
}
export function* LikeOrDislikeSaga() {
  yield takeEvery(Posts.LIKE_OR_DISLIKE_SAGA, likeOrDislike);
}
export function* AddCommentSaga() {
  yield takeEvery(Posts.ADD_COMMENT_SAGA, addComment);
}
export function* CommentDataSaga() {
  yield takeEvery(Posts.POST_DATA_SAGA, postPage);
}
export function* hidePostSaga() {
  yield takeEvery(Posts.HIDE_POST_SAGA, hidePost);
}
export function* postReportSaga() {
  yield takeEvery(Posts.POST_REPORT_SAGA, postReport);
}
export function* addViewSaga() {
  yield takeEvery(Posts.ADD_VIEW, addView);
}
// export function* popularMastersSaga() {
//   yield takeEvery(Posts.POPULAR_MASTER_SAGA, getPopularMasters);
// }
